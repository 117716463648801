import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";

const ObjectiveStateHistoryStartNoTrace = ({
  statusType,
  index
}: {
  statusType: StateStatus,
  index: number
}) => {
  
  return <div className={styles.tableCell}
  key={index}>
  <StatusIcon status={statusType} size={MuiSizes.SMALL} />
  </div>;
};

export default ObjectiveStateHistoryStartNoTrace;
