import {StateStatus} from "module/objective/domain/model/StateStatus";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";

export default interface ObjectiveStateResponse {
  id: string;
  comment: string;
  status: StateStatus;
  createdAt: string;
  endedAt: string | null;
}

export function objectiveStateResponseToModel(
  objectiveResponse: ObjectiveStateResponse
): ObjectiveState {
  return new ObjectiveState(
    objectiveResponse.id,
    objectiveResponse.comment,
    objectiveResponse.status,
    objectiveResponse.createdAt,
    objectiveResponse.endedAt
  );
}

export function objectiveStatesResponseToModel(
  objectiveStatesResponse: ObjectiveStateResponse[]
): ObjectiveState[] {
  return objectiveStatesResponse !== undefined ? objectiveStatesResponse
    .map(objectiveStateResponse => objectiveStateResponseToModel(objectiveStateResponse)) : [];
}
