import React from "react";
import DropdownThumbnail from "asset/component/module/DropdownThumbnail";
import Objective from "module/objective/domain/model/Objective";
import {useTranslation} from "react-i18next";
import {ErrorNames} from "core/error/ErrorNames";
import {
  Alert,
  CircularProgress,
  HideListedItemsButton,
  InputAutoUpdate,
  ModuleListing,
  ModuleSectionTitle,
  ProgressCircle
} from "asset/component";
import useErrorTranslation from "asset/component/hook/useErrorTranslation";
import SubObjective from "module/objective/domain/model/SubObjective";
import styles from "module/objective/view/asset/ObjectiveComponent.module.scss";
import ObjectiveComponentModuleListingStyles
  from "module/objective/view/asset/ObjectiveComponentModuleListing.module.scss";
import EditObjectiveDropdownThumbnailComponent
  from "module/objective/view/asset/ObjectiveDropdownThumbnailComponent.module.scss";
import useHideListedItem from "asset/component/hook/useHideListedItem";
import {Typography} from "@mui/material";
import InputEnter from "asset/component/form/inputs/InputEnter";
import StatusCircle from "module/objective/view/component/StatusIcon";
import ObjectiveStateComponent from "module/objective/view/component/ObjectiveStateComponent";
import HorizontalSeparatorLine, {SeparatorColor} from "asset/component/module/HorizontalSeparatorLine";
import ObjectiveState from "module/objective/domain/model/ObjectiveState";

const ObjectiveComponent = ({
  objective,
  objectiveStateHistory,
  completedSubObjectiveCount,
  isLoading,
  errorName,
  deleteObjective,
  isUpdateLoading,
  setName,
  setText,
  isAddSubObjectiveLoading,
  addSubObjective,
  addSubObjectiveErrorName,
  addObjectiveState,
  updateObjectiveState,
  fetchObjectiveStateHistory,
  isStateHistoryLoading,
  children,
  tags,
  isEditable = true
}: {
  objective: Objective,
  objectiveStateHistory: ObjectiveState[],
  completedSubObjectiveCount: number,
  isLoading?: boolean,
  isUpdateLoading?: boolean,
  errorName?: ErrorNames,
  deleteObjective?: (id: string) => void
  setName?: (name: string) => void
  setText?: (text: string) => void,
  addSubObjective?: (name: string) => void,
  isAddSubObjectiveLoading?: boolean,
  addSubObjectiveErrorName?: ErrorNames,
  addObjectiveState?: (status: string) => void,
  updateObjectiveState?: (comment:string) => void
  fetchObjectiveStateHistory?: () => void,
  isStateHistoryLoading?: boolean,
  children?: (subObjective: SubObjective) => React.ReactElement
  tags?: React.ReactElement,
  isEditable?: boolean
}) => {
  const {t} = useTranslation('objective');
  const {getErrorMessage} = useErrorTranslation();
  const {showCompletedItem, setShowCompletedItem, filterList} = useHideListedItem<SubObjective>('completed');
  
  const errorMessage =
    <Alert severity="error"
           data-testid="objective-error-message">{errorName && getErrorMessage(errorName)}</Alert>;
  
  const objectiveLoading =
    <CircularProgress data-testid="loading_objective" />;
  
  const tagsSection = tags ? <div className={styles.tagsSection}>
    <ModuleSectionTitle title={t('needs')} noMargin />
    <div className={styles.tags}>
      {tags}
    </div>
  </div> : <></>;
  
  const subObjectiveHeader =
    <div className={styles.subObjectiveHeader}>
      <div className={styles.progressCircle}>
        <ProgressCircle displayNumber completed={completedSubObjectiveCount} total={objective.subObjectives.length} />
      </div>
      <div className={styles.hideListedItemsButton}>
        <HideListedItemsButton showItems={showCompletedItem} setShowItems={setShowCompletedItem} />
      </div>
    </div>;
  
  const subObjectiveCheckboxListing = children ?
    <>
      <ModuleSectionTitle title={t('subObjectiveTitle')} />
      {subObjectiveHeader}
      <ModuleListing<SubObjective> elements={filterList(objective.subObjectives)}
                                   injectedStyle={ObjectiveComponentModuleListingStyles}
      >
        {
          (subObjective: SubObjective) => children(subObjective)
        }
      </ModuleListing>
    </> : <></>;
  
  const addSubObjectiveComponent = addSubObjective ?
    <div className={styles.input}>
      <InputEnter placeholder={t('addSubObjective')}
                  errorMessage={addSubObjectiveErrorName && getErrorMessage(addSubObjectiveErrorName)}
                  setValue={addSubObjective} />
    </div> : <></>;
  
  const objectiveDropdownThumbnail =
    <DropdownThumbnail title={objective.name}
                       isEditable={isEditable}
                       setTitle={setName}
                       editIsLoading={isUpdateLoading}
                       deleteAction={deleteObjective}
                       deleteConfirmationText={t('deleteConfirmation')}
                       icons={
                         [<ProgressCircle completed={completedSubObjectiveCount}
                                         total={objective.subObjectives.length}
                         />, <StatusCircle status={objective.state}/>]
                       }
                       injectedStyle={EditObjectiveDropdownThumbnailComponent}
    >
      <div className={styles.objectiveText}>
        <ModuleSectionTitle title={t('problemObservedTitle')} noMargin />
        <Typography className={styles.text} component="p" variant="xs">
          {objective.text}
        </Typography>
      </div>
      {subObjectiveCheckboxListing}
    </DropdownThumbnail>;
  
  const editableObjectiveDropdownThumbnail =
    <DropdownThumbnail title={objective.name}
                       setTitle={setName}
                       editIsLoading={isUpdateLoading}
                       deleteAction={deleteObjective}
                       deleteConfirmationText={t('deleteConfirmation')}
                       icons={
                          [<ProgressCircle completed={completedSubObjectiveCount}
                                           total={objective.subObjectives.length}
                          />, <StatusCircle status={objective.state}/>]
                       }
                       injectedStyle={EditObjectiveDropdownThumbnailComponent}
    >
      {tagsSection}
      <InputAutoUpdate title={<ModuleSectionTitle title={t('problemObservedTitle')} noMargin />}
                       initialValue={objective.text}
                       saveAction={(value) => setText!(value)}
                       isLoading={isUpdateLoading}
                       errorMessage={errorName && getErrorMessage(errorName)}
                       rows={7}
                       maxlength={250} />
      <div className={styles.objectiveState}>
        <HorizontalSeparatorLine color={SeparatorColor.WHITE}/>
        <ObjectiveStateComponent
          objectiveTitle={objective.name}
          objectiveStateHistory={objectiveStateHistory}
          addObjectiveState={addObjectiveState}
          updateObjectiveState={updateObjectiveState}
          fetchObjectiveStateHistory={fetchObjectiveStateHistory}
          isStateHistoryLoading={isStateHistoryLoading}/>
        <HorizontalSeparatorLine color={SeparatorColor.WHITE}/>
      </div>
      {subObjectiveCheckboxListing}
      {addSubObjectiveComponent}
    </DropdownThumbnail>;
  
  const renderObjective = () => {
    if (errorName) {
      return errorMessage;
    } else if (isLoading) {
      return objectiveLoading;
    } else if (isEditable) {
      return editableObjectiveDropdownThumbnail;
    } else {
      return objectiveDropdownThumbnail;
    }
  };
  
  return renderObjective();
};

export default ObjectiveComponent;
