import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";
import ChatBubble from "asset/icon/ChatBubble";

const ObjectiveStateHistoryMiddleTraceChatBubble = ({
  statusColor,
  index
}: {
  statusColor: StateStatusColor
  index: number
}) => {
  
  const cellStyles = {
    backgroundColor: statusColor
  }
  
  return <div key={index} style={cellStyles} className={styles.tableCell}>
    <div className={styles.tableCellChatBubble}><ChatBubble width={12} height={12} /></div>
    </div>
    ;
};

export default ObjectiveStateHistoryMiddleTraceChatBubble;

