import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";
import ChatBubble from "asset/icon/ChatBubble";


const ObjectiveStateHistoryEndTraceChatBubble = ({
  statusColor,
  index
}: {
  statusColor: StateStatusColor
  index: number
}) => {
  
  const cellStyles = {
    backgroundColor: statusColor,
    borderRadius: "0 50% 50% 0"
  }
  
  return <div key={index} style={cellStyles}
              className={styles.tableCell}>
      <div className={styles.tableEndCellChatBubble}><ChatBubble width={12} height={12} /></div>
    </div>
};

export default ObjectiveStateHistoryEndTraceChatBubble;

