import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";

const ObjectiveStateHistoryMiddleTrace = ({
  statusColor,
  index
}: {
  statusColor: StateStatusColor
  index: number
}) => {
  
  const cellStyles = {
    backgroundColor: statusColor
  }
  
  return <div className={styles.tableCell}
              key={index}
              style={cellStyles}/>
    ;
};

export default ObjectiveStateHistoryMiddleTrace;

