import {StateStatusColor} from "module/objective/domain/model/StateStatusColor";
import {StateStatus} from "module/objective/domain/model/StateStatus";
import styles from "module/objective/view/asset/ObjectiveStateHistoryModal.module.scss";
import StatusIcon from "module/objective/view/component/StatusIcon";
import {MuiSizes} from "style/props/MuiSizes";

const ObjectiveStateHistoryStartTrace = ({
  statusType,
  statusColor,
  index
}: {
  statusType: StateStatus,
  statusColor: StateStatusColor
  index: number
  }) => {
  
  const cellStyles = {
    backgroundColor: statusColor,
    borderRadius: "50% 0 0 50%"
  }
  
  return <div className={styles.tableCell}
              key={index}
              style={cellStyles}>
    <StatusIcon status={statusType} size={MuiSizes.SMALL} />
  </div>;
};

export default ObjectiveStateHistoryStartTrace;
